import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'

export const AccordionMain = styled.div`
  ${clamp('padding-top', 30, 50)}
`

export const AccordionToggle = styled.button`
  position: relative;
  display: block;
  width: 100%;
  ${clamp('padding-right', 24, 50)}
  text-align: left;
  color: currentColor;
  border: none;
  background-color: transparent;
`

export const AccordionToggleIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  ${clamp('width', 14, 25)}
`

export const AccordionContent = styled.div`
  overflow: hidden;
  will-change: height;
`

export const AccordionContentInner = styled.div`
  ${clamp('padding-top', 20, 44)}
`
