import { transparentize } from 'polished'
import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'

export const LineMain = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${props.delay}s`,
  },
}))`
  height: 0.1rem;
  background-color: ${transparentize(0.8, colors.dark)};
  transform-origin: 0% 50%;
  transform: ${props => `scaleX(${props.inView ? 1 : 0})`};
  transition: transform 0.6s ${easings.in.cubic};
`
