import React, { useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import {
  AccordionContent,
  AccordionContentInner,
  AccordionMain,
  AccordionToggle,
  AccordionToggleIcon,
} from './index.style'
import Line from '../Line'
import Spacer from '../Spacer'
import ToggleCross from '../svgs/ToggleCross'

/**
 * @param {func} callback must be a memoized function using useCallback
 */

const Accordion = ({
  toggle,
  callback,
  children,
  border = true,
  openStart = false,
}) => {
  const [open, setOpen] = useState(openStart)
  const $wrapper = useRef()
  const $content = useRef()
  const tl = useRef()

  useEffect(() => {
    const updateWrapperHeight = debounce(() => {
      const wrapperHeight = parseInt($wrapper.current.style.height, 10)

      if (wrapperHeight > 0) {
        gsap.set($wrapper.current, { height: $content.current.scrollHeight })
      }
    }, 200)

    tl.current = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.3,
        ease: 'power2.inOut',
      },
      onComplete: () => {
        if (callback) {
          callback()
        }
      },
    })

    window.addEventListener('resize', updateWrapperHeight)

    return () => {
      tl.current.kill()
      window.removeEventListener('resize', updateWrapperHeight)
    }
  }, [callback])

  useEffect(() => {
    tl.current
      .to($wrapper.current, {
        height: open ? $content.current.scrollHeight : 0,
      })
      .to(
        $content.current,
        {
          opacity: open ? 1 : 0,
        },
        open ? '<0.2' : '<'
      )
    tl.current.play()
  }, [open])

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen)
  }

  return (
    <AccordionMain>
      <AccordionToggle onClick={handleClick}>
        {toggle}
        <AccordionToggleIcon>
          <ToggleCross open={open} />
        </AccordionToggleIcon>
      </AccordionToggle>

      <AccordionContent ref={$wrapper}>
        <AccordionContentInner ref={$content} aria-hidden={!open}>
          {children}
        </AccordionContentInner>
      </AccordionContent>

      <Spacer size={[30, 50]} />

      {border && <Line />}
    </AccordionMain>
  )
}

Accordion.propTypes = {
  toggle: PropTypes.node.isRequired,
  callback: PropTypes.func,
  children: PropTypes.node,
  border: PropTypes.bool,
  openStart: PropTypes.bool,
}

export default Accordion
