import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { LineMain } from './index.style'

const Line = ({ delay = 0, children }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <LineMain ref={ref} inView={inView} delay={delay}>
      {children}
    </LineMain>
  )
}

Line.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
}

export default Line
