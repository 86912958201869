import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Container from '../components/Container'
import Grid from '../components/Grid'
import GridItem from '../components/GridItem'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Label,
  TextBodyLarge,
  TextBody,
  TextBodySmall,
  TextBodyXSmall,
  TextBodyXXSmall,
  TextCta,
} from '../components/TextStyles'
import Spacer from '../components/Spacer'
import Flow from '../components/Flow'
import ScrollSection from '../components/ScrollSection'
import AnimateSplitText from '../components/animation/AnimateSplitText'
import AnimateFadeIn from '../components/animation/AnimateFadeIn'
import AnimateSlideIn from '../components/animation/AnimateSlideIn'
import Tabs from '../components/Tabs'
import { colors } from '../styles/vars/colors.style'
import VimeoPlayer from '../components/VimeoPlayer'
import Button from '../components/Button'
import IconArrowheadLeft from '../components/svgs/IconArrowheadLeft'
import IconArrowheadRight from '../components/svgs/IconArrowheadRight'
import RichText from '../components/RichText'
import Accordion from '../components/Accordion'
import Cta from '../components/Cta'
import Input from '../components/Input'
import DotLabel from '../components/DotLabel'

const StyleguidePage = ({ data }) => {
  const [textInput, setTextInput] = useState('')
  const { seoTitle, seoDescriptions, slug, content } = data.contentfulStyleguide

  const dummyTabData = [
    {
      title: 'Tab 1',
      content: <TextBody>Tab 1 content</TextBody>,
    },
    {
      title: 'Tab 2',
      content: <TextBody>Tab 2 content</TextBody>,
    },
    {
      title: 'Tab 3',
      content: <TextBody>Tab 3 content</TextBody>,
    },
  ]

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />

      <ScrollSection>
        <Container>
          <Spacer size={[120, 260]} />

          <Grid>
            <GridItem tabletL={8} tabletLStart={5}>
              <Heading3 color={colors.green}>
                <AnimateSplitText>Text styles</AnimateSplitText>
              </Heading3>

              <Spacer size={[12, 24]} />

              <Flow scale={1.8}>
                <Heading1>
                  <AnimateSplitText>
                    We’ve Built Your Crypto Portfolio
                  </AnimateSplitText>
                </Heading1>

                <Heading2>
                  <AnimateSplitText>Full control</AnimateSplitText>
                </Heading2>

                <Heading3>
                  <AnimateSplitText>Ember Nuggets</AnimateSplitText>
                </Heading3>

                <Heading4>
                  <AnimateSplitText>
                    Easily manage your portfolios in app and keep track of your
                    investments.
                  </AnimateSplitText>
                </Heading4>

                <Label>
                  <AnimateSplitText>Your name</AnimateSplitText>
                </Label>

                <TextBodyLarge>
                  <AnimateSplitText>
                    Whether you’re new to crypto or an avid investor, we make it
                    easy for you to invest.
                  </AnimateSplitText>
                </TextBodyLarge>

                <TextBody>
                  <AnimateSplitText>
                    Retain full control of your own assets at all times without
                    trusting a third party. No frozen accounts, no central trove
                    of assets for hackers, and full transparency.
                  </AnimateSplitText>
                </TextBody>

                <TextBodySmall>
                  <AnimateSplitText>November 3, 2021</AnimateSplitText>
                </TextBodySmall>

                <TextBodyXSmall>
                  <AnimateSplitText>Twitter</AnimateSplitText>
                </TextBodyXSmall>

                <TextBodyXXSmall>
                  <AnimateSplitText>
                    © Ember Fund Inc. All Rights Reserved 2021.
                  </AnimateSplitText>
                </TextBodyXXSmall>

                <TextCta>
                  <AnimateSplitText>
                    Get Started – Download for free
                  </AnimateSplitText>
                </TextCta>
              </Flow>

              <Spacer size={[40, 80]} />

              {content && (
                <>
                  <Heading2 color={colors.green}>
                    <AnimateSplitText>Rich text</AnimateSplitText>
                  </Heading2>

                  <Spacer size={[12, 24]} />

                  <RichText content={content} />

                  <Spacer size={[40, 80]} />
                </>
              )}

              <Heading3 color={colors.green}>
                <AnimateSplitText>Components</AnimateSplitText>
              </Heading3>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Dot label</AnimateSplitText>
              </Heading4>

              <Spacer size={[12, 24]} />

              <DotLabel>
                <TextBodySmall>This is a dot label</TextBodySmall>
              </DotLabel>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Buttons</AnimateSplitText>
              </Heading4>

              <Spacer size={[12, 24]} />

              <Flow direction="horizontal">
                <AnimateFadeIn>
                  <Button onClick={() => alert('A primary click')}>
                    Primary button
                  </Button>
                </AnimateFadeIn>

                <AnimateFadeIn>
                  <Button size="small" onClick={() => alert('A primary click')}>
                    Primary button small
                  </Button>
                </AnimateFadeIn>
              </Flow>

              <Spacer size={[9, 18]} />

              <Flow direction="horizontal">
                <AnimateFadeIn>
                  <Button
                    variant="secondary"
                    onClick={() => alert('A secondary click')}
                  >
                    Secondary button
                  </Button>
                </AnimateFadeIn>

                <AnimateFadeIn>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => alert('A secondary click')}
                  >
                    Secondary button small
                  </Button>
                </AnimateFadeIn>
              </Flow>

              <Spacer size={[9, 18]} />

              <Flow direction="horizontal">
                <AnimateFadeIn>
                  <Button disabled onClick={() => {}}>
                    Disabled button
                  </Button>
                </AnimateFadeIn>

                <AnimateFadeIn>
                  <Button
                    type="externalLink"
                    href="https://toyfight.co"
                    target="_blank"
                    iconRight={<IconArrowheadRight responsive={false} />}
                  >
                    An external link
                  </Button>
                </AnimateFadeIn>
              </Flow>

              <Spacer size={[9, 18]} />

              <Flow direction="horizontal">
                <AnimateFadeIn>
                  <Button
                    type="internalLink"
                    to="/"
                    iconLeft={<IconArrowheadLeft responsive={false} />}
                  >
                    A page navigation button
                  </Button>
                </AnimateFadeIn>

                <AnimateFadeIn>
                  <Button type="internalLink" to="/" label="Go back">
                    <IconArrowheadLeft responsive={false} />
                  </Button>
                </AnimateFadeIn>
              </Flow>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Inputs</AnimateSplitText>
              </Heading4>

              <Spacer size={[24, 48]} />

              <Input
                id="input-name"
                name="input-name"
                label="Your name"
                value={textInput}
                onChange={e => setTextInput(e.currentTarget.value)}
              />

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>CTAs</AnimateSplitText>
              </Heading4>

              <Spacer size={[24, 48]} />

              <Flow direction="horizontal">
                <Cta type="externalLink" href="https://toyfight.co">
                  An external link
                </Cta>

                <Cta type="internalLink" to="/">
                  An internal link
                </Cta>

                <Cta type="button" onClick={() => alert('You clicked a Cta')}>
                  A button CTA
                </Cta>
              </Flow>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Accordion</AnimateSplitText>
              </Heading4>

              <Spacer size={[12, 24]} />

              <AnimateSlideIn>
                <Accordion
                  toggle={<Heading2 as="span">Toggle the content</Heading2>}
                >
                  <TextBody>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </TextBody>
                </Accordion>
              </AnimateSlideIn>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Vimeo Player</AnimateSplitText>
              </Heading4>

              <Spacer size={[12, 24]} />

              <AnimateSlideIn>
                <VimeoPlayer id="148751763" />
              </AnimateSlideIn>

              <Spacer size={[24, 48]} />

              <Heading4>
                <AnimateSplitText>Tabs</AnimateSplitText>
              </Heading4>

              <Spacer size={[12, 24]} />

              <AnimateSlideIn>
                <Tabs data={dummyTabData} />
              </AnimateSlideIn>
            </GridItem>
          </Grid>

          <Spacer size={[120, 360]} />
        </Container>
      </ScrollSection>
    </>
  )
}

export default StyleguidePage

export const pageQuery = graphql`
  query StyleguidePage($slug: String!) {
    contentfulStyleguide(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      content {
        raw
        references {
          ...ContentfulRichTextReferences
        }
      }
    }
  }
`
